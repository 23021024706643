/*  Pulling the svg icon down by -0.125em places the icon onto the proper baseline of the text. */

.if-svg-icon__baseline {
  display: inline-block;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

/* svg viewBox width: 320px */

.if-svg-icon__baseline.if-w-10 {
  width: 0.625em;
}

/* svg viewBox width: 384px */

.if-svg-icon__baseline.if-w-12 {
  width: 0.75em;
}

/* svg viewBox width: 448px */

.if-svg-icon__baseline.if-w-14 {
  width: 0.875em;
}

/* svg viewBox width: 512px */

.if-svg-icon__baseline.if-w-16 {
  width: 1em;
}

/* svg viewBox width: 576px */

.if-svg-icon__baseline.if-w-18 {
  width: 1.125em;
}

/* svg viewBox width: 640px */

.if-svg-icon__baseline.if-w-20 {
  width: 1.25em;
}

/* svg viewBox Fixed Width */

.if-svg-icon__baseline.if-fw {
  text-align: center;
  width: 1.25em;
}

[dir="rtl"] .rtlFlip {
  transform: scaleX(-1);
}
